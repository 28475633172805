import React, { FC, lazy, Suspense, Fragment } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { RoutePaths } from './routePaths';
import { LoadingPage } from 'components/common/loading';
import { AvailableLanguages, useLanguage } from 'components/hooks/language/useLanguage';

const HomePage = lazy(() => import('components/pages/home/HomePage'));
const ServicesPage = lazy(() => import('components/pages/services/ServicesPage'));
const SolutionsPage = lazy(() => import('components/pages/solutions/SolutionsPage'));
const RequestAccessPage = lazy(() => import('components/pages/requestAccess/RequestAccessPage'));
const UnsubscribePage = lazy(() => import('components/pages/unsubscribe/UnsubscribePage'));
const UnsubscribeValidatedPage = lazy(() => import('components/pages/unsubscribe/UnsubscribeValidatedPage'));

export const AppRoutes: FC = () => {
    return (
        <Suspense fallback={<LoadingPage />}>
            <Routes>
                {AvailableLanguages.map(lng => <Fragment key={lng}>
                    <Route path={RoutePaths.Home.route(lng)} element={<HomePage />}>
                        <Route path={RoutePaths.ServiceDetailModal.SubPath.subRoute} element={<Fragment />} />
                    </Route>

                    <Route path={RoutePaths.Services.Default.route(lng)} element={<ServicesPage />} >
                        <Route path={RoutePaths.ServiceDetailModal.SubPath.subRoute} element={<Fragment />} />
                    </Route>

                    <Route path={RoutePaths.Solutions.Default.route(lng)} element={<SolutionsPage />} >
                        <Route path={RoutePaths.ServiceDetailModal.SubPath.subRoute} element={<Fragment />} />
                    </Route>

                    {/* Route For Request Access From */}
                    <Route path={RoutePaths.RequestAccess.Default.route(lng)} element={<RequestAccessPage />} />

                    {/* Route For Request Demo From */}
                    <Route path={RoutePaths.RequestDemo.Default.route(lng)} element={<RequestAccessPage demoForm />} />

                    {/* Route For Client Subscription List */}
                    <Route path={RoutePaths.Unsubscribe.Default.route(lng)} element={<UnsubscribePage />} />
                    <Route path={RoutePaths.UnsubscribeValidated.Default.route(lng)} element={<UnsubscribeValidatedPage />} />
                </Fragment>)}

                <Route path="*" element={<RedirectComponent />} />
            </Routes>
        </Suspense>
    );
};

const RedirectComponent: FC = () => {
    const { selectedLanguage, urlLanguage } = useLanguage();
    const { pathname, search, hash } = useLocation();

    if (urlLanguage) {
        // Route already have language in URL, but no route where found. Redirect to the home
        return <Navigate to={RoutePaths.Home.url(urlLanguage)} />;
    }

    // Route doesn't have language in URL. Add it.
    return <Navigate to={`/${selectedLanguage}${pathname}${search}${hash}`} />;
};