import { init as initApm } from '@elastic/apm-rum';
import { configuration, Environment, getEnvironment } from './constants';

export const configureRum = () => {
    initApm({
        serviceName: 'franfinance-web-enterprise',
        serverUrl: configuration.sgMonitoringRumUrl,
        environment: getRumEnvironment(),
    });
};

const getRumEnvironment = () => {
    const environment = getEnvironment();
    switch (environment) {
        case Environment.Local:
            return 'LOCAL';

        case Environment.Development:
            return 'DEV';

        case Environment.Homologation:
            return 'UAT';

        case Environment.Preview:
            return 'PREVIEW';

        case Environment.Production:
            return 'PRD';

        case Environment.Test:
            return 'TEST';

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const exhaustiveCheck: never = environment;
    }
};
